<template>
  <div class="text-center">
    <v-snackbar :value="snackbar" :timeout="2500" :top="true" :color="color" >
      <span class="black--text">{{ message }}</span>
      
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
        >
          <v-icon color="black">mdi-check</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Snackbar",
  data() {
    return {
      // text: this.$t("message.title-edit-account-success-message"),
      color: "#ff0090"
    };
  },
  props: {
    snackbar: Boolean,
    message: String,
  },
  computed: {
  },
  method() {},
};
</script>

<style></style>
